import * as React from "react";
import {FC, useEffect, useMemo, useState} from "react";
// @ts-ignore
import * as style from './FilterField.module.scss';
import classNames from "classnames/bind";
import {Client, handleApiError} from "../../services/ApiService";
import {Accordion, Card, Col, Form, Row} from "react-bootstrap";
import VendorSearchBar from "../VendorSearchBar/VendorSearchBar";
import Loading from "../Loading/Loading";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import './slider.scss';
import './categories.scss';
import {useStateCallback} from "../../hooks/use-state-callback";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart, faTimes} from "@fortawesome/pro-light-svg-icons";
import {useSelector} from "react-redux";
import {RootState} from "@reduxjs/toolkit/dist/query/core/apiState";
import {faArrowTu} from "@fortawesome/pro-light-svg-icons";
import {bookmarkIcon} from "../../PageTypes/ProductPage/ProductPage.module.scss";
import {ArraySchema} from "yup";
import {forEach} from "react-bootstrap/ElementChildren";


const {createSliderWithTooltip} = Slider;
const Range = createSliderWithTooltip(Slider.Range);


type FFProps = {
    filteringDone(vendors: Array<any>): void,
    filteringDoneMax(vendors: Array<any>): void,
    propsProducts(products: Array<any>): void,
    withoutBL?: boolean,
    type: string,
    page?: number,
    setLoading(loadingState: boolean): void,
    setPage(page: number): void
    cats: boolean
}

/* Welcome to Hell, Soldier
*  If you've come here to fix a bug, you're probably on your own now. This thing is unfixable.
*  Wish you the best of luck.
*  (im really sorry you have to go through this.)
*  Thanks for nothing i guess :)
* */

const FilterField: FC<FFProps> = ({
                                      filteringDone,
                                      filteringDoneMax,
                                      page,
                                      withoutBL,
                                      type,
                                      setLoading,
                                      setPage,
                                      propsProducts,
                                      cats
                                  }) => {
    const [initFilter, setInitFilter] = useState(false);
    const [categories, setCategories] = useState([]);
    const [filteredVendors, setFilteredVendors] = useState([]);
    const [allSelectedProducts, setAllSelectedProducts] = useState([]);
    const [filteredMaxVendors, setFilteredMaxVendors] = useState([]);
    const [vendorCats, setVendorCats] = useState([]);
    const [states, setStates] = useState([]);
    const [maxPrice, setMaxPrice] = useState(400);
    const [minPrice, setMinPrice] = useState(0);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [passedSearchVal, setPassedSearchVal] = useState('');
    const [passedCategoryID, setPassedCategoryID] = useState(0);
    const [open, setOpen] = useState([]);
    const randSeed = useMemo(() => Math.floor(Math.random() * 1000), []);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [filter, setFilter] = useState([]);
    const [isCats, seIsCats] = useState(cats);

    const [BL, setBL] = useStateCallback('', () => handleSearch());
    const [priceValues, setPriceValues] = useStateCallback([0, 400], () => handleSearch());
    const [sorting, setSorting] = useStateCallback('', () => handleSearch());
    const [checked, setChecked] = useStateCallback([], () => {
        handleSearch()
        updateCategoryURL();
    });
    const [productsLoading, setProductsLoading] = useState(true)
    const [searchTerm, setSearchTerm] = useStateCallback(new URLSearchParams(location.search).get('search') ?? '', () => {
        handleSearch();
        updateSearchURL();
    });
    const [blockSearch, setBlockSearch] = useState(false);


    const pageSize = 42;


    useEffect(() => {
        setSearchTerm(new URLSearchParams(location.search).get('search') ?? '');
    }, [location.search]);

    function handleOpenClick(id) {
        if (open.includes(id)) {

            setOpen(open.filter((x) => x !== id))


        } else {
            setOpen([...open, id]);
        }

    }


    useEffect(() => {
        //handleSearch();
        setChecked(initChecked());
    }, [page]);

    useEffect(() => {
        Client.Raw.get('Category/0/tree?Action=flat').then((res) => {
            if (allSelectedProducts && allSelectedProducts.length > 0) {
                const filteredProducts = [];
                for (const cat of res.data) {
                    // console.log("Cat:")
                    // console.log(cat)
                    for (const selectedProduct of allSelectedProducts) {
                        if (selectedProduct.ID === cat.ID) {
                            filteredProducts.push(cat);
                            break;
                        }
                    }
                }

                // console.log("Filtered Cats:")
                // console.log(filteredProducts)
                setCategories(filteredProducts);

            }
        }).catch(handleApiError)
    }, [allSelectedProducts]);

    useEffect(() => {
        console.log(propsProducts)

        const prices = propsProducts.map((item) => item.Price);

        if (prices.length > 0) {
            const lowestPrice = Math.min(...prices);
            const highestPrice = Math.max(...prices);

            setMaxPrice(highestPrice +1);
            setMinPrice(lowestPrice +1);

        }

    }, [propsProducts])

    useEffect(() => {
        setLoading(true)

        setSelectedProducts(propsProducts)


        console.log(propsProducts)

        setProductsLoading(true);
        Client.Raw.get('Category/0/tree?Action=flat').then((res) => {
            setCategories(res.data);

        }).catch(handleApiError)

        Client.Raw.get('State').then((res) => {
            setStates(res.data);
        }).catch(handleApiError);


        Client.Raw.get('Product', {
            params: {
                'filter[Hidden]': 0,
                'filter[Draft]': 0,
                'sort': 'RAND(' + randSeed + ')',
                'limit': 1,
            }
        }).then((res) => {
            if (res.data[0]) {
                Client.Raw.get('Product', {
                    params: {
                        'filter[Hidden]': 0,
                        'sort': 'Price DESC',
                        'limit': 1
                    }
                }).then((res) => {
                    if (res.data[0]) {
                        setInitFilter(true)
                        setPriceValues([0, (parseInt(res.data[0].Price) + 1)])
                        if (!isCats){
                            setMaxPrice(parseInt(res.data[0].Price) + 1)
                        }
                    }
                })
            }
        }).catch(((error) => {
            handleApiError(error)
        }))


    }, [])

    useEffect(() =>{
        console.log("Categories:")
        console.log(categories)
    }, [categories])

    function initChecked() {
        let tmp = new URL(window.location).searchParams.getAll("cid[]") ?? [];
        return tmp.length > 0 ? tmp.map(i => parseInt(i)) : [];
    }

    function updateSearchURL() {
        //stackoverflow told me this is good, not sure if it is
        if (searchTerm) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?search=' + searchTerm;
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    function updateCategoryURL() {
        if (!searchTerm) {
            let catString = '?';

            for (let i = 0; i < checked.length; i++) {
                catString += 'cid[]=' + checked[i] + '&'
            }

            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + catString;
            window.history.pushState({path: newurl}, '', newurl);
        }
    }

    function handleSearch() {
        if (!blockSearch) {
            setLoading(true);
            setProductsLoading(true);
            let promoted = [];

            if (checked.length == 0 && !searchTerm && page === 0) {
                Client.Raw.get('Product/getPromotedProducts', {}).then((res) => {
                    promoted = res.data;
                }).catch(handleApiError)
            }

            if (type === "vendor") {
                Client.Raw.get('Vendor/getActiveVendorsMinimalized', {params: getSearchParams(1)}).then((res) => {
                    setFilteredVendors(res.data);

                }).catch(handleApiError)
            }
            //note: whoever made this should be hanged in public
            // rude :(
            //I understand nothing anymore... what's happening when and where?


            if (selectedProducts && selectedProducts.length > 0) {
                if (type === "product") {

                    let offset = (page * pageSize);
                    const limitedFilteredProducts = selectedProducts.slice(offset, offset + pageSize);

                    setFilteredVendors(limitedFilteredProducts);
                    setFilteredMaxVendors(selectedProducts);
                }

                //pls help me out of here, im lost

            } else {
                if (type === "product") {
                    Client.Raw.get('Product/getActiveProductsMinimalized', {params: getSearchParams(1)}).then((res) => {
                        setFilteredVendors(res.data);
                    }).catch(handleApiError)

                    Client.Raw.get('Product/getActiveProductsMinimalized', {params: getSearchParams(2)}).then((res) => {
                        setFilteredMaxVendors(res.data);
                    }).catch(handleApiError)
                }
            }
        }
    }

    function getSearchParams(value) {
        let params = {};

        if (type === 'product') {
            params['filter[Hidden]'] = '0';
            params['filter[Draft]'] = '0';

            if (searchTerm) {
                params['filter[Title:PartialMatch]'] = searchTerm;
            }
            if (BL && BL !== '0') {
                params['filter[Vendor.Address.StateID]'] = BL;
            }
            if (checked.length) {
                params['filter[Category.ID]'] = checked;
            }

            if (priceValues.length) {
                params['filter[Price:GreaterThanOrEqual]'] = priceValues[0];
                params['filter[Price:LessThanOrEqual]'] = priceValues[1];
            }
            if (sorting) {
                params['sort'] = sorting;
            } else {
                params['sort'] = 'RAND(' + randSeed + ')';
            }
            if (value !== 3) {
                if (checked.length == 0) {
                    params['filter[Promoted]'] = '0';
                }
            }

            if (value === 1) {
                if (page !== undefined) {
                    params['offset'] = page * pageSize;
                    params['limit'] = pageSize
                } else {
                    params['limit'] = 100
                }
            } else if (value === 3){
                params['noCats'] = '0';
                if (priceValues.length) {
                    params['filter[Price:GreaterThanOrEqual]'] = priceValues[0];
                    params['filter[Price:LessThanOrEqual]'] = priceValues[1];
                }
            } else {
                params['limit'] = 10000000000;
            }

        }

        return params;
    }


    function filterCategories(e) {
        setPage(0)

        if (checked.includes(parseInt(e.target.id))) {
            setChecked(checked.filter(x => x !== parseInt(e.target.id)))

        } else {
            setChecked([...checked, parseInt(e.target.id)]);
        }
    }

    let fakeChecked = [];
    let fakeRemove = [];

    function displayAllProductsFromCategory(catObj: object) {
        let addArray: Array<number> = [];
        let children: Array<object> = catObj.Children ?? [];
        setPage(0)

        if (!checked.includes(parseInt(catObj.ID))) {
            //add
            addArray.push(catObj.ID);
            if (children.length > 0) {
                children.map((item: object, key) => {
                    if (!checked.includes(parseInt(item.ID))) {
                        addArray.push(parseInt(item.ID));
                        displayAllProductsFromCategory(item);
                    }
                })
            }
        } else {
            //delete
            fakeRemove.push(parseInt(catObj.ID));
            if (children.length > 0) {
                children.map((item: object, key: number) => {
                    if (checked.includes(parseInt(item.ID))) {
                        fakeRemove.push(parseInt(item.ID));
                        displayAllProductsFromCategory(item);
                    }
                })
            }
        }

        fakeChecked = (fakeChecked.concat(addArray))
        setChecked(fakeChecked.concat(checked).filter((item) => {
            return !fakeRemove.includes(item)
        }));

        return fakeChecked;
    }


    function getAllCatsLmao(cat) {
        let sub = [];

        if (cat && cat.Children) {
            for (let child of cat.Children) {
                sub = sub.concat(getAllCatsLmao(child));
            }
        }

        if (cat) {
            sub.push(cat);
        }

        return sub;
    }

    useEffect(() => {
        const catid = new URLSearchParams(location.search).get("catid")?.toString();

        if (catid || passedCategoryID) {
            setLoading(true);
            setBlockSearch(true);

            // Parse the `catid` value as an integer and store it in `passedCategoryID`
            catid ? setPassedCategoryID(parseInt(catid)) : null;

            let allCats = [];

            // Create an array containing the `catid` or `passedCategoryID` value
            let addArray = [catid ? catid : passedCategoryID];

            // Traverse the `categories` tree and get all the `cat` values
            for (let cat of categories) {
                allCats = allCats.concat(getAllCatsLmao(cat));
            }

            // Check if any `cat` values were found
            if (allCats.length > 0) {
                // Check if any of the `cat` values match the `catid` or `passedCategoryID` value
                for (let cat of allCats) {
                    for (let addCat of addArray) {
                        if (cat.ID === addCat) {
                            // Update the URL and add the matching `cat` value to the `checked` array
                            const newUrl = new URL(window.location.href);
                            newUrl.search = "";
                            window.history.pushState({path: newUrl.href}, "", newUrl.href);
                            setChecked(checked.concat(addArray.concat(displayAllProductsFromCategory(cat))));
                            setPassedCategoryID(null);
                            setLoading(false);
                            setBlockSearch(false);
                        }
                    }
                }
            }
        }


    }, [location.search, categories])

    useEffect(() => {
        filteringDone(filteredVendors)
        setLoading(false)
        setProductsLoading(false)
    }, [filteredVendors])

    useEffect(() => {
        filteringDoneMax(filteredMaxVendors)
        setLoading(false)
        setProductsLoading(false)
    }, [filteredMaxVendors])


    function handleSortFilter(event) {
        setSorting(event.target.value)
    }

    function renderAccordion(item, subItems) {

        return item.Children?.length > 0 ?
            <Accordion className={'mb-3 border-0 border-bottom pb-1 ' + (productsLoading ? 'disabled-filters' : '')}
                       defaultActiveKey={(0).toString()}>
                <Card className={'bg-transparent text-black border-0'}>

                    <Row>
                        <Col>
                            <Form.Check id={'checkAllCats-' + (item.ID)} type="checkbox"
                                        checked={checked.includes(item.ID) || checked.includes((item.ID).toString())}
                                        onChange={(event) => {
                                            displayAllProductsFromCategory(item);
                                        }}
                                        label={item.Title}
                                        className={'d-inline-block'}
                            />
                        </Col>
                        <Col>
                            <Accordion.Toggle
                                className={'bg-transparent p-0 border-0 ' + style.cursorPointer + ' ' + ((open.includes(item.ID) ? style.arrowUp : style.arrowDown))}
                                as={Card.Header} eventKey={item.ID} onClick={() => handleOpenClick(item.ID)}>
                            </Accordion.Toggle>
                        </Col>
                    </Row>
                    <Accordion.Collapse eventKey={item.ID}>
                        <Card.Body className={'ps-0 ms-3'}>

                            {subItems.map((subItem, subKey) => {
                                return subItem.Children?.length > 0 ?
                                    renderAccordion(subItem, subItem.Children) :
                                    (subItem.ProductCount >= 1 ?
                                        <Form.Group controlId={subItem.ID} className="mb-3">
                                            <Form.Check id={subItem.ID} type="checkbox"
                                                        checked={checked.includes(subItem.ID) || checked.includes((subItem.ID).toString())}
                                                        onChange={(event) => filterCategories(event)}
                                                        label={subItem.Title}/>
                                        </Form.Group> : null)
                            })}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion> : <Form.Group controlId={item.ID} className="mb-3 ">
                <Form.Check id={item.ID} type="checkbox"
                            checked={checked.includes(item.ID) || checked.includes((item.ID).toString())}
                            onChange={(event) => filterCategories(event)}
                            label={item.Title}/>
            </Form.Group>
    }

    let cl = classNames.bind(style);
    return (
        <>
            <div className={style.dropdown}>
                <div className="row justify-content-center align-items-center pb-4">
                    <Col xs={12} className={"d-flex justify-content-center"}>
                        <VendorSearchBar handleVendorSearch={(term) => {
                            if (term !== searchTerm) {
                                setSearchTerm(term);
                            }
                        }}/>
                    </Col>
                    <Col xs={12}>
                        {states.length ?
                            <Form.Group controlId="blFormSelect"
                                        className={"w-100 d-flex justify-content-center mb-4 " + style.dropdownWrapper}>
                                <Form.Control as="select" className={style.blDropdown}
                                              onChange={(event) => setBL(event.target.value)}>
                                    <option value={0}>Alle Regionen</option>
                                    {states.map((state, index) => {
                                        return <option value={state.ID} key={index}>{state.Title}</option>
                                    })}
                                </Form.Control>
                            </Form.Group>
                            : <Loading type={"border"} message={"Daten werden geladen"}/>
                        }
                    </Col>

                    <Col xs={12}>
                        <Form.Group controlId={"sort"} className={style.dropdownWrapper}>
                            <Form.Control as="select" onChange={(event) => handleSortFilter(event)}
                                          className={style.blDropdown}>
                                <option disabled={true} selected>Sortieren nach</option>
                                <option
                                    value={"Title ASC"}>{type === 'vendor' ? 'HändlerInnenname' : 'Produktname'} aufsteigend
                                </option>
                                <option
                                    value={"Title DESC"}>{type === 'vendor' ? 'HändlerInnenname' : 'Produktname'} absteigend
                                </option>
                                {type !== 'vendor' ?
                                    <>
                                        <option value={"Price ASC"}>Preis aufsteigend</option>
                                        <option value={"Price DESC"}>Preis absteigend</option>
                                        <option value={"CategoryID DESC"}>Kategorie</option>
                                    </>
                                    : null}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </div>
                <div className={"row justify-content-center align-items-center pb-4"}>

                    <Col className={style.filterCategories + " pb-3"} xs={12}>
                        {/*<Col className={style.filterCategories + (isCats ? ' ' + style.noCats : '') + " pb-3"} xs={12}>*/}
                        <p className={style.catHeading}>
                            Kategorien
                            <span className={style.auswahl}> (Mehrfachauswahl möglich) </span>
                        </p>
                        <hr/>

                        {categories.map((item, key) => {
                            return (
                                item.Children?.length > 0 ?
                                    <Accordion defaultActiveKey={item.ID}
                                               className={'mb-2 pb-1 border-0 border-bottom ' + (productsLoading ? 'disabled-filters' : '')}>
                                        <Card className={'bg-transparent text-black border-0'}>
                                            <Row>
                                                <Col>
                                                    <Form.Check id={'checkAllCats-' + ((item.ID).toString())}
                                                                type="checkbox"
                                                                checked={checked.includes(item.ID) || checked.includes((item.ID).toString())}
                                                                onChange={(event) => {
                                                                    displayAllProductsFromCategory(item)
                                                                }}
                                                                label={item.Title}
                                                                className={''}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Accordion.Toggle
                                                        className={'bg-transparent p-0 border-0 ' + style.cursorPointer + ' ' + (open.includes(item.ID) ? style.arrowUp : style.arrowDown)}
                                                        as={Card.Header} eventKey={(item.ID).toString()}
                                                        onClick={(e) => handleOpenClick(item.ID)}>
                                                    </Accordion.Toggle>
                                                </Col>
                                            </Row>
                                            <Accordion.Collapse eventKey={(item.ID).toString()}>
                                                <Card.Body className={'ms-3 mt-0 ps-0'}>
                                                    {item.Children.map((subItem, subKey) => {
                                                        return subItem.Children?.length > 0 ?
                                                            renderAccordion(subItem, subItem?.Children) :
                                                            (subItem.ProductCount >= 1 ?
                                                                <Form.Group controlId={subItem.ID} className="mb-3">
                                                                    <Form.Check id={subItem.ID} type="checkbox"
                                                                                checked={checked.includes(subItem.ID) || checked.includes((subItem.ID).toString())}
                                                                                onChange={(event) => filterCategories(event)}
                                                                                label={subItem.Title}/>
                                                                </Form.Group> : null)

                                                    })}
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion> : <Form.Group controlId={item.ID} className="mb-3">
                                        <Form.Check id={item.ID} type="checkbox"
                                                    checked={checked.includes(item.ID) || checked.includes((item.ID).toString())}
                                                    onChange={(event) => filterCategories(event)}
                                                    label={item.Title}/>
                                    </Form.Group>
                            )
                        })}

                    </Col>
                    <hr/>
                    {type === "vendor" ? null :
                        <>
                            <Col xs={12} className={"d-flex flex-column justify-content-center "}
                                 style={{marginBottom: isCats ? 0 : 300, paddingBottom: isCats ? 150 : 0}}>
                                <Form.Group controlId="formBasicRange" className="w-100">
                                    <Form.Label className={style.catHeading}>Preis</Form.Label>
                                    {initFilter &&
                                        <Range
                                            className={style.rangeSlider}
                                            min={minPrice}
                                            max={maxPrice}
                                            // @ts-ignore
                                            defaultValue={priceValues}
                                            tipFormatter={value => `€ ${value}`}
                                            onAfterChange={(value) => {
                                                setPriceValues(value);
                                            }}/>
                                    }
                                </Form.Group>
                                <Row className={"pt-2"}>
                                    <Col lg={6} md={6} className={"d-flex justify-content-start"}>
                                            <span style={{color: '#fff'}}>
                                                € {maxPrice}
                                            </span>
                                    </Col>
                                    <Col lg={6} md={6} className={"d-flex justify-content-end"}>
                                            <span style={{color: '#fff'}}>
                                                € {maxPrice}
                                            </span>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default FilterField;
