import * as React from "react";
import {FC, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight, faSlidersH, faTimes} from "@fortawesome/pro-light-svg-icons";
import {Col, Row} from "react-bootstrap";
// @ts-ignore
import iconImage from './Icons/arrow-left-light.svg';
import {slide as FilterMenu} from 'react-burger-menu';
import StickyBox from "react-sticky-box";
// @ts-ignore
import * as style from './MarketplaceFilter.module.scss';
import FilterField from "../../../components/FilterField/FilterField";
import {Button} from "bootstrap";
import {Client, handleApiError} from "../../../services/ApiService";
import debounce from "@popperjs/core/lib/utils/debounce";
import {bottom} from "@popperjs/core";

type FFProps = {
    filteringDone(vendors: Array<any>): void,
    filteringDoneMax(vendors: Array<any>): void,
    propsProducts(products: Array<any>): void,
    setLoading(state: boolean): void,
    page?: number,
    filterHeight: any,
    setPage(page: number): void
    cats: boolean,
    filterID: string,
    showFilter: boolean
}


const MarketplaceFilter: FC<FFProps> = ({page, filteringDone, filterHeight, setLoading, setPage, filteringDoneMax, propsProducts, filterID, cats, showFilter}) => {
    const [isMobile, setIsMobile] = useState(false);
    const [filterStatus, setFilterStatus] = useState(false);
    const [products, setProducts] = useState([]);
    const [maxProducts, setMaxProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [isSticky, setIsSticky] = useState(false);
    const [isFixed, setIsFixed] = useState(false);
    const [mobileFilter, setMobileFilter] = useState(false);
    const [maxPageSize, setMaxPageSize] = useState(1);
    const [bottomVal, setBottomVal] = useState('auto');


    useEffect(() => {
        setIsSticky(true);
        console.log("ShowFiler in MarketplaceFilter")
        console.log(showFilter)
    }, [])

    useEffect(() => {
        if(cats){
            console.log("hide filter")
            setFilterStatus(false)
        }
    }, [])

    const handleScroll = debounce(() => {
        if (window.scrollY >= 120) {
            setMobileFilter(true)
        } else {
            setMobileFilter(false);
        }


        //This is the most goofy thing I programmed so far I think. :^)
        // I hope no one is ever going to see this.

        if (filterID === ''){
            const element = document.getElementById('marketplacewrapper-js');
            const rect = element.getBoundingClientRect();

            const threshold = 100;

            if (rect.top <= threshold) {
                setIsFixed(true);
            } else if (rect.top > threshold) {
                setIsFixed(false);
            }

            if (rect.bottom <= 0) {
                setIsFixed(false);
            }

        }else{
            //LANDINGPAGES

            const filter = document.getElementById(filterID);
            const rectLP = filter.getBoundingClientRect();

            const thresholdLP = 100;

            if (rectLP.top <= thresholdLP) {
                setIsFixed(true);
                setBottomVal(window.innerHeight - rectLP.bottom)
            } else if (rectLP.top > thresholdLP) {
                setIsFixed(false);
            }

            if(rectLP.top > 0){
                setBottomVal('auto')
            }

            if (rectLP.bottom <= 200) {
                setIsFixed(false);
            }
        }

    }, 200);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    useEffect(() => {
        window.scrollTo({
            top: 1,
            behavior: "smooth"
        });
    }, [page]);


    function changeFilter() {
        setFilterStatus(false);
        document.body.style.overflow = "visible"
    }

    function disableScroll() {
        console.log("stuff happening, i hope");
        document.body.style.overflow = "hidden"
    }

    useEffect(() => {
        if(cats){
            setFilterStatus(false)
            setIsMobile(window.innerWidth < 991)
        }else {
            setFilterStatus(window.innerWidth >= 991)
            setIsMobile(window.innerWidth < 991)
        }
    }, [window.innerWidth]);


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const searchTerm = searchParams.get('search');
    }, [location.search])

    useEffect(() => {
        filteringDone(products)
    }, [products])

    useEffect(() => {
        filteringDoneMax(maxProducts)
    }, [maxProducts])

    useEffect(() => {
        setSelectedProducts(selectedProducts)
    }, [selectedProducts])

    const styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: '36px',
            top: '36px'
        },
        bmBurgerBars: {
            background: '#373a47'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px',
            display: 'block'
        },
        bmCross: {
            background: 'transparent',
            color: '#004036',
            width: '15px',
        },
        bmMenuWrap: {
            position: 'absolute',
            width: '300px',
            height: '90vh',
            zIndex: '9999',
            display: 'block',
            boxShadow: '0px 0px 2px 1px rgba(0, 0, 0, 0.2)',
            bottom: bottomVal
        },
        bmMenu: {
            background: '#fff',
            zIndex: '9999',
            display: 'block',
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#004036',
            fontSize: '15px',
            letterSpacing: '0.9px',
            fontFamily: 'Titillium Web',
            fontWeight: 'bold',
        },
        bmItem: {
            display: 'block'
        }
    };

    return (
        <StickyBox className={style.stickyBoxWrapper} offsetTop={0} offsetBottom={0}>
            <>
                <div>
                    <div onClick={() => {
                        setFilterStatus(!filterStatus);
                    }}
                         className={(showFilter ? ' d-none ' : '') + ' ' +(cats ? style.noCatsToggle : '')+ ' ' + style.filterToggleWrapper + (isSticky ? ' ' + style.isSticky : '') + (isFixed ? ' ' + style.isFixedFilter : '')}>
                        <div className={style.normalFilter}>
                            <div className={style.filterToggleArrowOpen}>
                                <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
                            </div>
                            <FontAwesomeIcon icon={faSlidersH}></FontAwesomeIcon>
                        </div>

                        <span onClick={disableScroll} className={style.filterText}>FILTER</span>
                    </div>
                </div>
                {isMobile ? <div className={style.mobileFilterHeading}>
                    <FilterMenu
                        customCrossIcon={<FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>}
                        className={style.mobileFilterContainer + ' ' + (mobileFilter ? style.mobileFilterContainerScrolled : '')}
                        styles={styles}
                        noOverlay
                        left
                        isOpen={filterStatus}
                        onClose={changeFilter}
                    >
                        <Row>
                            <Col xs={12}>
                                <FilterField setPage={setPage} page={page} type={"product"} filteringDone={setProducts}
                                             filteringDoneMax={setMaxProducts}
                                             setLoading={setLoading}
                                             withoutBL={false} propsProducts={propsProducts} cats={cats}/>
                                <span id={'searchItemsButton'} onClick={changeFilter} className={cats ? style.catsButton + ' ' + style.customButton : style.customButton}>Filter anwenden!</span>
                            </Col>
                        </Row>
                    </FilterMenu>
                </div> :
                    <div className={style.normalFilterHeading}>
                        <FilterMenu
                            customCrossIcon={null}
                            className={(cats ? style.noCats : '') +  ' ' + style.normalFilterContainer + ' FilterMenu ' + (isSticky ? ' ' + style.isSticky : '') + (isFixed ? ' ' + style.isFixed : '')}
                            styles={styles}
                            noOverlay
                            left
                            isOpen={filterStatus}
                            onClose={() => setFilterStatus(false)}
                        >
                            <Row>
                                <Col xs={12}>
                                    <div className={'text-end'}>
                                        <Row>
                                            <Col xs={10}>
                                                <div className={''}>Filter</div>
                                            </Col>
                                            <Col xs={2}>
                                                <FontAwesomeIcon icon={faArrowLeft} onClick={() => {
                                                    setFilterStatus(false)
                                                }}></FontAwesomeIcon>
                                            </Col>
                                        </Row>
                                    </div>
                                    <FilterField setPage={setPage} page={page} type={"product"} filteringDone={setProducts}
                                                 filteringDoneMax={setMaxProducts}
                                                 setLoading={setLoading}
                                                 withoutBL={false} propsProducts={propsProducts} cats={cats}/>
                                </Col>
                            </Row>
                        </FilterMenu>
                    </div>}
            </>
        </StickyBox>
    );
}

export default MarketplaceFilter;